<template>
  <!-- index.vue -->

  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    class="app-admin-wrap-layout-6"
  >
    <base-view />
    <v-fade-transition v-if="getAppLoading">
      <v-overlay color="primary lighten-5" class="progress-overlay-loading">
          <v-card class="mx-auto dark--text" color="white" striped max-width="1000" :loading="getAppLoading">
            <template slot="progress">
              <v-progress-linear color="secondary" indeterminate></v-progress-linear>
            </template>
            <v-card-text class="dark--text text-center font-weight-medium">
              Payment Progressing....This will take a moment......Don't Refresh or Reload the Page.....
            </v-card-text>
          </v-card>
      </v-overlay>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FullLayout",
  components: {
    BaseView: () => import("@/layouts/component/View"),
  },
  computed: {
    ...mapGetters(["getThemeMode", "getAppLoading"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
<style lang="scss">
.app-admin-wrap-layout-6 {
  .sidebar-six {
    &.theme--dark.v-navigation-drawer {
      background-color: #111827;
      // background-color: #111827;
    }

    // .top-toolbar {
    //   background-color: #111827;
    // }
  }
}
div.theme--dark .v-navigation-drawer {
  background-color: #272727 !important;
}
</style>
